import React from 'react'
import { Link } from 'gatsby'
import {
  faDiscord,
  faPatreon,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'

import Icon from '@/components/Icon'
import BoostyIcon from '@/images/boosty.png'

import './AboutBlockStyles.less'

const AboutBlock = () => (
  <div className='AboutBlock'>
    <a
      className='AboutBlock_link'
      href='https://discord.gg/wqr4eANcDz'
      rel='nofollow noopener noreferrer'
      target='_blank'
      title='Discord-сервер проекта'
    >
      <Icon
        icon={faDiscord}
        className='AboutBlock_discordIcon'
      />
    </a>
    <a
      className='AboutBlock_link'
      href='https://t.me/dm_stuff_ru'
      rel='nofollow noopener noreferrer'
      target='_blank'
      title='Telegram-канал проекта'
    >
      <Icon
        icon={faTelegram}
        className='AboutBlock_telegramIcon'
      />
    </a>
    <a
      className='AboutBlock_link'
      href='https://boosty.to/dm-stuff'
      rel='nofollow noopener noreferrer'
      target='_blank'
      title='Страница проекта на Boosty'
    >
      <Icon
        icon={BoostyIcon}
        className='AboutBlock_boostyIconIcon'
      />
    </a>
    <a
      className='AboutBlock_link'
      href='https://www.patreon.com/dm_stuff_ru'
      rel='nofollow noopener noreferrer'
      target='_blank'
      title='Страница проекта на Patreon'
    >
      <Icon
        icon={faPatreon}
        className='AboutBlock_patreonIcon'
      />
    </a>
    <Link
      to='/about'
      className='AboutBlock_page'
    >
      О проекте
    </Link>
  </div>
)

export default AboutBlock
