import React from 'react'

import Layout from '@/components/Layout'
import AboutBlock from '@/components/AboutBlock'
import PageTitle from '@/components/PageTitle'
import Seo from '@/components/Seo'
import SubpageList from '@/components/SubpageList'

import {catalogPageList, generatorPageList} from '@/constants/menuList'

const DndPage = () => (
  <Layout>
    <Seo
      title='Dungeons & Dragons'
      description='Инструменты для игры в Dungeons & Dragons'
    />
    <PageTitle>Инструменты для игры в Dungeons & Dragons</PageTitle>
    <h2>Каталоги</h2>
    <SubpageList list={catalogPageList}/>
    <h2>Генераторы</h2>
    <SubpageList list={generatorPageList}/>
    <AboutBlock/>
  </Layout>
)

export default DndPage
